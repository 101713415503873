import { appointmentDurationInMinutes } from '@global/utils/domain/appointment';
import { ProfileImage } from '@lp-root/src/components/obj.person-card-template/person-card-template.component.style';
import { Body, BodySecondary, Col, H2, Row, Separator } from '@web/atomic';
import * as React from 'react';

interface HcpProfileHeaderProps {
  profileUrl: string;
  hcpName: string;
  professionalId: string;
  fullDescription: string;
  appointmentDurationInMinutes: number;
}

export const HcpProfileHeader: React.FC<HcpProfileHeaderProps> = (props) => {
  return (
    <Col xs={12} md={6}>
      <Row center="xs" mb>
        <Col xs={12} md={5}>
          <ProfileImage src={props.profileUrl} alt={`Foto de ${props.hcpName}`} />
        </Col>
        <Col xs={12} md={7}>
          <H2>{props.hcpName}</H2>
          <Body>{`CRP: ${props.professionalId}`} </Body>
          <BodySecondary>{`Duração: ${appointmentDurationInMinutes} minutos`} </BodySecondary>
        </Col>
      </Row>
      <Separator />
      {props.fullDescription && (
        <Body>
          <p dangerouslySetInnerHTML={{ __html: props.fullDescription }} />
        </Body>
      )}
    </Col>
  );
};
