import { Col, H3, Row } from '@web/atomic';
import * as React from 'react';
import { HcpProfileDescriptionSectionUL } from './hcp-profile-descrition.style';

export const HcpProfileDescriptionSection: React.FC<{ title: string; items?: string[] }> = (input) => {
  const { title, items } = input;

  if (!items || items?.length === 0) return null;

  return (
    <Row>
      <Col xs={12} md={3}>
        <H3>{title}</H3>
      </Col>
      <Col xs={12} md={6}>
        <HcpProfileDescriptionSectionUL>
          {items.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </HcpProfileDescriptionSectionUL>
      </Col>
    </Row>
  );
};
