import { appointmentDurationInMinutes } from '@global/utils/domain/appointment';
import { Col, Grid, LargeSeparator, Row, Separator } from '@web/atomic';
import { MatchMedia } from '@web/atomic/legacy/obj.match-media';
import { HttpDataSource } from '@web/data/vigilantes.datasource';
import * as React from 'react';
import { HcpProfileDescriptionSection } from '../components/hcp-description-section/hcp-profile-descrition.component';
import { HcpProfileHeader } from '../components/hcp-profile-header/telehealth-profile-header.component';
import { ScheduleFormData } from '../schedule-page/schedule-form-row.component';
import { SchedulePageOnProfileWrapper } from './schedule-on-profile.wrapper';

interface HcpProfileTemplateProps {
  hcp: {
    id: string;
    profileUrl: string;
    name: string;
    professionalId: string;
    appointmentDurationInMinutes: number;
    fullDescription: string;
    themes: string[];
    approaches: string[];
    education: string[];
  };
  user?: {
    id: string;
    hasDavId?: boolean;
  };
  nextAvailableAppointmentDate: string;
  datasource: HttpDataSource;
  handleScheduleSuccess: () => void;
  onRedirectRegisterPageClick: (data: ScheduleFormData) => void;
}

const HcpProfileTemplate: React.FunctionComponent<HcpProfileTemplateProps> = (props) => {
  const { hcp, user, datasource } = props;

  return (
    <>
      <Separator />
      <Grid>
        <Row between="xs">
          <HcpProfileHeader
            profileUrl={hcp.profileUrl}
            hcpName={hcp.name}
            professionalId={hcp.professionalId}
            appointmentDurationInMinutes={appointmentDurationInMinutes}
            fullDescription={hcp.fullDescription}
          />
          <MatchMedia defaultMinWidth={`64em`}>
            {(match) =>
              match ? null : (
                <Col xs={12} md={6}>
                  <SchedulePageOnProfileWrapper
                    datasource={datasource}
                    userId={user.id}
                    hcpId={hcp.id}
                    nextAvailableAppointmentDate={props.nextAvailableAppointmentDate}
                    userHasDavId={user.hasDavId}
                    onScheduleSuccess={props.handleScheduleSuccess}
                    onRedirectRegisterPageClick={props.onRedirectRegisterPageClick}
                  />
                </Col>
              )
            }
          </MatchMedia>
        </Row>
        <LargeSeparator />
        <HcpProfileDescriptionSection title="TEMAS" items={hcp.themes} />
        <Separator />
        <HcpProfileDescriptionSection title="ABORDAGENS" items={hcp.approaches} />
        <Separator />
        <HcpProfileDescriptionSection title="FORMAÇÃO" items={hcp.education} />
        <MatchMedia defaultMinWidth={`64em`}>
          {(match) =>
            !match ? null : (
              <Row between="xs">
                <Col xs={12}>
                  <SchedulePageOnProfileWrapper
                    datasource={datasource}
                    userId={user.id}
                    hcpId={hcp.id}
                    nextAvailableAppointmentDate={props.nextAvailableAppointmentDate}
                    userHasDavId={user.hasDavId}
                    onScheduleSuccess={props.handleScheduleSuccess}
                    onRedirectRegisterPageClick={props.onRedirectRegisterPageClick}
                  />
                </Col>
              </Row>
            )
          }
        </MatchMedia>
      </Grid>
      <Separator />
    </>
  );
};

export default HcpProfileTemplate;
