import { QuickReplyListStyled } from '@lp-root/src/components/atm.quick-reply/quick-reply-list.component.style';
import { StartSection } from '@lp-root/src/components/mol.section-start/section-start.component';
import { Col, Row, Separator } from '@web/atomic';
import { TextShimmerBoxStyled } from '@web/atomic/legacy/mol.shimmer/shimmer.component.style';
import { ShimmerBox, ShimmerButton, ShimmerInput, ShimmerText } from '@web/atomic/legacy/mol.shimmer/shimmer.options';
import { LoadingState } from '@web/atomic/legacy/obj.loading-state';
import { HttpDataSource } from '@web/data/vigilantes.datasource';
import * as React from 'react';
import { ScheduleFormData, ScheduleFormRow } from '../schedule-page/schedule-form-row.component';
import { useHealthCarerAgenda, usePostAppointment } from '../schedule-page/schedule.hooks';

export interface IScheduleFormRowWrapperProps {
  datasource: HttpDataSource;
  userId: string;
  hcpId: string;
  nextAvailableAppointmentDate: string;
  /**
   * true = YES, false = NO, undefined = UNKNOWN
   */
  userHasDavId?: boolean;
  onScheduleSuccess?: () => void;
  onRedirectRegisterPageClick: (data: ScheduleFormData) => void;
}

export const SchedulePageOnProfileWrapper: React.FunctionComponent<IScheduleFormRowWrapperProps> = (props) => {
  const { datasource, userId, hcpId, onRedirectRegisterPageClick, nextAvailableAppointmentDate, onScheduleSuccess, userHasDavId } = props;

  const {
    data: healthCarerAgenda,
    error: errorFromGetHealthCarerAgenda,
    loading: loadingFromGetHealthCarerAgenda,
  } = useHealthCarerAgenda(datasource, hcpId, nextAvailableAppointmentDate);

  React.useEffect(() => {
    if (errorFromGetHealthCarerAgenda) {
      console.error(
        `ERROR: payment-options.tsx:315 ~ useHealthCarerAgenda error: ${errorFromGetHealthCarerAgenda}, data: ${healthCarerAgenda}, hcpId: ${hcpId} nextAvailableAppointmentDate: ${nextAvailableAppointmentDate}`
      );
    }
  }, [errorFromGetHealthCarerAgenda]);

  const [formData, setFormData] = React.useState<ScheduleFormData>();

  const [postAppointment, { loading: loadingFromPostAppointment }] = usePostAppointment({
    datasource: datasource,
    onSuccess: onScheduleSuccess,
    onUserDoesntExistOnDavError: () => onRedirectRegisterPageClick(formData),
  });

  const handleScheduleSubmit = async (data: ScheduleFormData) => {
    setFormData(data);
    if (userHasDavId) {
      try {
        await postAppointment({
          ...data,
          userId,
          hcpId,
        });
      } catch (error) {
        console.error('ERROR: schedule-on-profile.wrapper.tsx:58 ~ handleScheduleSubmit ~ error:', error);
      }
    } else {
      onRedirectRegisterPageClick(data);
    }
  };

  const availableDateTimes = healthCarerAgenda?.available_start_date_time;

  return (
    <LoadingState loading={loadingFromGetHealthCarerAgenda} error={!!errorFromGetHealthCarerAgenda} data={!!availableDateTimes}>
      <LoadingState.Shimmer>
        <Row mb center={'xs'}>
          <Col xs={12} md={10}>
            <StartSection noMarginTop title={`Agendar teleconsulta`} />
            <Separator />
            <ShimmerInput />
            <TextShimmerBoxStyled height="16px" margin={'46px 0 0 0'} />
            <Separator />
            <QuickReplyListStyled>
              {Array(5)
                .fill(1)
                .map((_n, i) => (
                  <ShimmerBox key={i} width="90px" height="51px" margin={'0 8px 8px 0'} />
                ))}
            </QuickReplyListStyled>
            <Separator />
            <ShimmerButton />
          </Col>
        </Row>
      </LoadingState.Shimmer>
      <LoadingState.Error>
        <></>
      </LoadingState.Error>
      <ScheduleFormRow
        title="Agendar teleconsulta"
        initialDate={new Date(availableDateTimes?.[0])}
        schedulableDates={availableDateTimes?.map((a) => new Date(a))}
        onSubmit={handleScheduleSubmit}
        submitLoading={loadingFromPostAppointment}
      />
    </LoadingState>
  );
};
