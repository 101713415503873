import { appointmentDurationInMinutes } from '@global/utils/domain/appointment';
import { NativeConfigWebviewExtraDataHcpProfile } from '@global/utils/native/native-config';
import {
  CustomWebviewMessageForTehealthRegister,
  CustomWebviewMessageForTeleatendimentoAgendar,
} from '@global/utils/native/native-custom-webview-message';
import { InternalHealthCarerProfileTemplateProps } from '@lp-root/page-creators/hcp-profile.page-creator';
import WebviewLayout from '@lp-root/src/components/org.layout/webview-layout.component';
import { PageProps } from '@lp-src/utils/local-types';
import { NativeHelper } from '@web/data/native.helper';
import { createInsomniacDataSource } from '@web/data/vigilantes.datasource.factory';
import { graphql } from 'gatsby';
import * as React from 'react';
import { ScheduleFormData } from '../schedule-page/schedule-form-row.component';
import HcpProfileTemplate from './telehealth-profile';
import { HcpProfileTemplateQueryOutput } from './telehealth.utils';

const TelehealthProfileNative: React.FunctionComponent<
  PageProps<InternalHealthCarerProfileTemplateProps, HcpProfileTemplateQueryOutput>
> = (props) => {
  const datasource = React.useRef(createInsomniacDataSource());
  const userId = NativeHelper.userId;
  const { education, approaches, themes, health_carer, profile_url, full_description } = props.data?.hasura.health_carer_telehealth?.[0];
  const { nextAppointmentDate, hasDavId } = (NativeHelper.extraData as NativeConfigWebviewExtraDataHcpProfile) ?? {};

  const handleScheduleSuccess = () => {
    const msg: CustomWebviewMessageForTeleatendimentoAgendar = {
      type: 'custom',
      id: 'success',
    };
    NativeHelper.postMessage(msg);
  };

  const redirectToRegisterPage = (data: ScheduleFormData) => {
    const msg: CustomWebviewMessageForTehealthRegister = {
      type: 'custom',
      id: 'push_to_register',
      data: {
        hcpId: health_carer.id,
        date: data.date?.toISOString(),
        time: data.time,
      },
    };
    NativeHelper.postMessage(msg);
  };

  return (
    <WebviewLayout hideThemeChanger skipMessengerExtensionLoad>
      <HcpProfileTemplate
        user={{
          id: userId,
          hasDavId: !!hasDavId,
        }}
        hcp={{
          id: health_carer.id,
          profileUrl: profile_url,
          name: health_carer.name,
          professionalId: health_carer.professional_id,
          appointmentDurationInMinutes,
          fullDescription: full_description,
          themes,
          approaches,
          education,
        }}
        nextAvailableAppointmentDate={nextAppointmentDate}
        datasource={datasource.current}
        handleScheduleSuccess={handleScheduleSuccess}
        onRedirectRegisterPageClick={redirectToRegisterPage}
      />
    </WebviewLayout>
  );
};

export default TelehealthProfileNative;

export const query = graphql`
  query HcpProfileTemplateQuery($hcpId: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    hasura {
      health_carer_telehealth(where: { health_carer_id: { _eq: $hcpId } }) {
        dav_id
        approaches
        education
        profile_url
        themes
        description
        full_description
        health_carer {
          id
          name
          professional_id
          professional_id_state
        }
      }
    }
  }
`;
